import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
export class AuthenticationGuard implements CanActivate {
    currentRouteURL:any;
    event$:any;
    constructor(private _router: Router) {

    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        if (!!localStorage.getItem('currentUser_LOMAH') == true) {
            return true;
        } 
        this._router.navigate(['login']);
        
    }
   
}