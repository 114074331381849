<ngx-spinner
  name="langLoading"
  bdColor="rgba(0, 0, 0, 0.5)"
  size="medium"
  color="#fff"
  type="timer"
  [fullScreen]="true"
></ngx-spinner>

<header
  class="clearfix"
  [attr.nav-layout]="navLayout"
  [attr.navbar-type]="toggleStatus ? defaultNavbar : toggleNavbar"
  [attr.effect-type]="navbarEffect"
  [attr.header-theme]="headerColorTheme"
  [attr.collapsed-left-header]="collapsedLeftHeader"
  [attr.device-type]="deviceType"
>
  <div class="lfd-header-wrapper clearfix">
    <div class="lfd-header-navbar">
      <div class="lfd-left-header clearfix">
        <i
          nz-icon
          nzType="menu"
          nzTheme="outline"
          (click)="changeTheLeftPanelStatus()"
          *ngIf="
            !(
              _router.url.includes('dashboard') || _router.url.includes('login')
            )
          "
        ></i>
        <span class="hide-mobile-view" translate>
          <img
            src="assets/logo_dark.png"
            alt="Range Management System"
            style="max-width: 320px"
          />
        </span>
        <span class="show-mobile-view">RMS</span>
      </div>

      <div class="lfd-right-header clearfix">
        <ul>
          <nz-select
            *ngIf="!_router.url.includes('login')"
            name="selectedDashboard"
            [(ngModel)]="selectedDashboard"
            class="header-dropdown dashboard-dropdown mr-3"
            (ngModelChange)="changeDashboard($event)"
          >
            <nz-option
              *ngFor="let dashboard of availableDashboards"
              [nzValue]="dashboard"
              [nzLabel]="dashboard"
            ></nz-option>
          </nz-select>

          <nz-select
            name="selectedLang"
            [(ngModel)]="selectedLang"
            class="header-dropdown mr-3"
            (ngModelChange)="changeLang()"
            *ngIf="showingLangs && showingLangs.length"
          >
            <nz-option
              *ngFor="let lang of showingLangs"
              [nzValue]="lang"
              [nzLabel]="lang"
            ></nz-option>
          </nz-select>

          <li *ngIf="!_router.url.includes('login')" translate>Welcome</li>
          <li *ngIf="!_router.url.includes('login')">
            <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
              <strong>{{ userName }} &nbsp;</strong>
              <i nz-icon nzType="down"></i>
            </a>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item (click)="gotoRoute('/pages/user-profile')">
                  <i nz-icon nzType="profile" nzTheme="outline"></i>
                  <span translate>Profile</span>
                </li>
                <li
                  nz-menu-item
                  *ngIf="authService.currentUserRole === 'AD'"
                  (click)="gotoRoute('/pages/set-machine-status')"
                >
                  <i nz-icon nzType="question-circle" nzTheme="outline"></i>
                  <span translate>Machine Status</span>
                </li>
                <!-- <li nz-menu-item (click)="gotoRoute('/config')">
                  <i nz-icon nzType="setting" nzTheme="outline"></i> Config
                </li> -->
                <li nz-menu-item (click)="LogOut()">
                  <i nz-icon nzType="logout" nzTheme="outline"></i
                  ><span translate>Logout</span>
                </li>
              </ul>
            </nz-dropdown-menu>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>
