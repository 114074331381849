<ngx-spinner name="langLoading" bdColor = "rgba(0, 0, 0, 0.5)" size = "medium" color = "#fff" type = "timer" [fullScreen] = "true"></ngx-spinner>

<nz-layout class="w-100 d-block">

  <app-header [navLayout]="setNavLayout" [defaultNavbar]="setDefaultNavbar" [toggleNavbar]="setToggleNavbar"
  [navbarEffect]="setVerticalNavbarEffect" [headerColorTheme]="setHeaderColorTheme"
  [navbarColorTheme]="setNavbarColorTheme" [activeNavColorTheme]="setActiveNavColorTheme"
  [headerHeight]="setHeaderHeight" [leftHeaderColorTheme]="setLeftHeaderColorTheme"
  [collapsedLeftHeader]="setCollapsedLeftHeader" [deviceType]="setDeviceType"></app-header>

  <nz-content class="w-100 d-flex flex-column">
    <div class="username-pen" nz-tooltip nzTooltipTitle="Want to edit username?" (click)="EditUsername()">
      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 317.037 317.037" style="enable-background:new 0 0 317.037 317.037;" xml:space="preserve">
  <g>
    <polygon style="fill:#010002;" points="313.831,39.438 280.718,6.325 115.724,147.817 172.339,204.432 	"/>
    <polygon style="fill:#010002;" points="164.665,211.28 108.882,155.492 58.293,228.75 91.406,261.863 	"/>
    <path style="fill:#010002;" d="M51.418,300.638l30.655-39.798l-23.143-22.724L22.988,265.23c0,0-1.556,33.428-22.898,51.807
      C0.095,317.037,25.261,297.75,51.418,300.638z M40.387,288.955c-3.911,0-7.082-3.171-7.082-7.082s3.171-7.082,7.082-7.082
      s7.082,3.171,7.082,7.082S44.298,288.955,40.387,288.955z"/>
    <path style="fill:#010002;" d="M286.402,0.005l30.546,30.546C317.035-1.132,286.402,0.005,286.402,0.005z"/>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
  <g>
  </g>
      </svg>
    </div> 
    <div class="login-container">
      <div class="thumb-container">
        <!-- <h4><a >Want to edit username?</a></h4> -->
        <div class="card">
          <div>
            <h2 translate>Hello</h2>
            <h4 translate>Password</h4>
          </div>
          <div class="thumb-circle">
            <i nz-icon nzType="eye" nzTheme="outline"></i>
          </div>
          <form class="d-flex" [formGroup]="loginForm" autocomplete="off">
              <nz-input-group [nzSuffix]="suffixTemplate">
                <input
                  [type]="showPassword ? 'text' : 'password'"
                  formControlName="password"
                  nz-input
                  placeholder="Enter password"
                  style="height:35px"
                />
              </nz-input-group>
              <ng-template #suffixTemplate>
                <span
                  style="cursor: pointer;"
                  nz-icon
                  [nzType]="showPassword ? 'eye' : 'eye-invisible'"
                  (click)="showPassword = !showPassword"
                ></span>
              </ng-template>
            <button type="submit" class="btn btn-primary ml-10" (click)="Login()" [disabled]="!loginForm.valid">
              <ngx-bootstrap-spinner
              name="loginLoading"
              bdColor="rgba(0, 0, 0, 0.8)"
              size="small"
              color="#d91d1d"
              type="border"
              isButtonSpinner="true"
              autoDisableButton="true"
              loadingText=""
            >
            </ngx-bootstrap-spinner>
              <span translate>Login</span></button>
            </form>
          <!-- <div class="login-wrapper">
            <p translate>or_Enter_your_credentials_to_access_your_account.</p>  
          </div> -->
        </div>
      </div>
      <div class="option-container">
        <hr>
        <span translate>OR</span>
      </div>
      <div class="upload-container">
        <div class="card">
          <div>
            <h2 translate>Upload_your_photo</h2>
            <h4 translate>Sign_in_through_Face_Recognition</h4>
          </div>
          <div class="thumb-circle" *ngIf="!isFaceSearch" #imagePreview>
            <img [src]="(webcamImage && webcamImage._imageAsDataUrl ? webcamImage._imageAsDataUrl : './assets/images/camera.png')" alt="Camera" [ngClass]="{'webCamImageClass':webcamImage && webcamImage._imageAsDataUrl}">
          </div>
          <webcam
          *ngIf="isFaceSearch"
          [width]="344"
          [height]="250"
          [trigger]="triggerObservable"
          (imageCapture)="handleImage($event)"
        ></webcam>

          <div class="login-wrapper d-flex">
            <button type="submit" class="btn btn-primary scan-btn" (click)="OpenCamera()" *ngIf="!webcamImage && !isFaceSearch"><span translate>Start_Scan</span></button>
            <button type="submit" class="btn btn-primary scan-btn" (click)="ResetImage()" *ngIf="webcamImage || isFaceSearch"><span translate>Reset</span></button>
            <button type="submit" class="btn btn-primary scan-btn" (click)="UploadImage()" *ngIf="webcamImage">
              <ngx-bootstrap-spinner
              name="uploadCameraLoading"
              bdColor="rgba(0, 0, 0, 0.8)"
              size="small"
              color="#d91d1d"
              type="border"
              isButtonSpinner="true"
              autoDisableButton="true"
              loadingText=""
            >
            </ngx-bootstrap-spinner>
              <span translate>Upload</span></button>
            <button type="submit" class="btn btn-primary scan-btn" *ngIf="isFaceSearch" (click)="takeSnapShot()"><span translate>Take_Snapshot</span></button>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper d-flex">
      <div>
        <img src="./assets/images/warning.png" alt="">
      </div>
      <div class="d-flex flex-column">
        <span translate>You_have_problem_with_login?</span>
        <a href="javascript:" translate>Connect_with_us</a>
      </div>
    </div>
  </nz-content>
  <nz-footer class="w-100 d-flex">powered by <span>L</span><span>F</span><span>D</span>&nbsp; {{copyRightYear}}</nz-footer>
</nz-layout>



