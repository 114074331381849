import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  public rSidebarToggleStatus = false;
  // Common Settings for Horizontal and Vertical Navigation
  public navLayout = 'vertical'; // Value Should be 'horizontal' or 'vertical'
  public toggleStatus = true; // Value Should be 'true' or 'false'
  public themeLayout = 'wide'; // value Should be 'wide', 'box'
  public headerHeight = 65;
  public asidebarHeight = window.innerHeight;
  public contentHeight = window.innerHeight - this.headerHeight;

  public headerColorTheme = 'theme1';
  public leftHeaderColorTheme = 'theme2';
  public navbarColorTheme = 'theme2';
  public activeNavColorTheme = 'theme1';

  // Setting Only for Vertical
  public collapsedLeftHeader = false; // valshould be true, false
  public toggleOnHover = true;

  public hmacEnabled = true;

  public leftPanelToggle = new BehaviorSubject<boolean>(false);
  public changeInSessionStorageUserName = new BehaviorSubject<boolean>(false);

  public defaultNavbar: string = ''; // Value Should be 'expended', 'collapsed', 'offcanvas', 'compact'
  public toggleNavbar: string = ''; // Value Should be 'expended', 'collapsed', 'offcanvas', 'compact'
  public navBarEffect: string = ''; // Value Should be 'shrink', 'push', 'overlay'
  public deviceType: string = ''; // Value should be mobile, tablet, desktop
  private SECRET_KEY = 'sk_test_4eC39HqLyjWDarjtT1zdp7dc';
  public ErrorMessage = new BehaviorSubject<any>('');

  // defaultVerticalMenu[0] = Default menu on mobile
  // defaultVerticalMenu[1] = Default menu on tablet
  // defaultVerticalMenu[2] = Default menu on desktop

  public defaultVerticalMenu = ['offcanvas', 'offcanvas', 'offcanvas'];

  // onToggleVerticalMenu[0] = Toggle menu on mobile
  // onToggleVerticalMenu[1] = Toggle menu on tablet
  // onToggleVerticalMenu[2] = Toggle menu on desktop

  public onToggleVerticalMenu = ['offcanvas', 'offcanvas', 'offcanvas'];

  // navBarMenuEffect[0] = Sidebar Toggle effect on mobile
  // navBarMenuEffect[1] = Sidebar Toggle effect on tablet
  // navBarMenuEffect[2] = Sidebar Toggle effect on desktop

  public navBarMenuEffect = ['overlay', 'push', 'shrink'];

  // defaultDeviceType[0] = breakpoint for Mobile
  // defaultDeviceType[1] = breakpoint for tablet
  // defaultDeviceType[2] = breakpoint for desktop

  public defaultDeviceType = ['mobile', 'tablet', 'desktop'];

  private authURL = environment.serverIP;
  private checkUserNameURL = 'api/check_username/';

  public editUserName = new BehaviorSubject<string>('');
  private setNavLayout = new BehaviorSubject<string>(this.navLayout);
  navLayoutCast = this.setNavLayout.asObservable();

  private setCollapsedLeftHeader = new BehaviorSubject<boolean>(
    this.collapsedLeftHeader
  );
  collapsedLeftHeaderCast = this.setCollapsedLeftHeader.asObservable();

  private tStatus = new BehaviorSubject<boolean>(this.toggleStatus);
  tStatusCast = this.tStatus.asObservable();

  private dfNavbar = new BehaviorSubject<string>(this.defaultNavbar);
  dfNavbarCast = this.dfNavbar.asObservable();

  private tNavbar = new BehaviorSubject<string>(this.toggleNavbar);
  toggleNavbarCast = this.tNavbar.asObservable();

  private nvEffect = new BehaviorSubject<string>(this.navBarEffect);
  nvEffectCast = this.nvEffect.asObservable();

  private setCtHeight = new BehaviorSubject<any>(this.contentHeight);
  contentHeightCast = this.setCtHeight.asObservable();

  private setAsidebarHeight = new BehaviorSubject<any>(this.asidebarHeight);
  setAsidebarHeightCast = this.setAsidebarHeight.asObservable();

  private setHeaderTheme = new BehaviorSubject<string>(this.headerColorTheme);
  headerThemeCast = this.setHeaderTheme.asObservable();

  private setLeftHeaderTheme = new BehaviorSubject<string>(
    this.leftHeaderColorTheme
  );
  leftHeaderThemeCast = this.setLeftHeaderTheme.asObservable();

  private setNavbarTheme = new BehaviorSubject<string>(this.navbarColorTheme);
  navbarThemeCast = this.setNavbarTheme.asObservable();

  private SetActiveNavTheme = new BehaviorSubject<string>(
    this.activeNavColorTheme
  );
  activeNavThemeCast = this.SetActiveNavTheme.asObservable();

  private SetThemeLayout = new BehaviorSubject<string>(this.themeLayout);
  themeLayoutCast = this.SetThemeLayout.asObservable();

  private appDeviceType = new BehaviorSubject<string>(this.deviceType);
  deviceTypeCast = this.appDeviceType.asObservable();

  public rsToggleStatus = new BehaviorSubject<boolean>(
    this.rSidebarToggleStatus
  );
  rsToggleStatusCast = this.rsToggleStatus.asObservable();

  public HMACEnabled: BehaviorSubject<boolean> = new BehaviorSubject(
    this.hmacEnabled
  );

  public selectedLanguage: BehaviorSubject<string> = new BehaviorSubject('en');

  public langLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private http: HttpClient) {}
  getToggleStatus() {
    this.toggleStatus = !this.toggleStatus;
    this.tStatus.next(this.toggleStatus);
  }
  getDefaultNavbar(defaultNavbar: string) {
    this.dfNavbar.next(defaultNavbar);
  }
  getToggleNavbar(toggleNavbar: string) {
    this.tNavbar.next(toggleNavbar);
  }
  getNavBarEffect(navbarEffect: string) {
    this.nvEffect.next(navbarEffect);
  }
  getDeviceType(dt: string) {
    this.appDeviceType.next(dt);
  }
  getThemeLayout(tl: string) {
    this.SetThemeLayout.next(tl);
  }
  getCollapsedLeftHeader(clh: boolean) {
    this.setCollapsedLeftHeader.next(clh);
  }
  getNavLayout(nl: string) {
    this.setNavLayout.next(nl);
  }
  getLeftHeaderThemeOnChange(themeName: string) {
    this.setLeftHeaderTheme.next(themeName);
  }
  getHeaderThemeOnChange(themeName: string) {
    this.setHeaderTheme.next(themeName);
  }
  getAsidebarThemeOnChange(themeName: string) {
    this.setNavbarTheme.next(themeName);
  }

  setHeader() {
    let options = undefined;
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Content-Type': 'application/json; charset=utf-8',
      timestamp: new Date().toISOString(),
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
    });
    options = {
      headers: headers,
    };

    return options;
  }

  checkUserName(username): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(
          this.authURL + this.checkUserNameURL,
          { username: username },
          this.setHeader()
        )
        .subscribe(
          (res) => {
            let response: any = res;
            if (response.error == '') {
              if (response.data) {
                observer.next({
                  status: 'ok',
                  authenticated: response.data,
                  userStatus: response.status,
                });
                observer.complete();
              } else {
                observer.next({
                  status: 'ok',
                  authenticated: response.data,
                  userStatus: '',
                });
                observer.complete();
              }
            } else {
              observer.next({ status: 'error', msg: response.error });
              observer.complete();
            }
          },
          (err) => {
            this.ErrorMessage.next('Error in validating username!');
            observer.next({ status: 'error', err: err });
            observer.complete();
          }
        );
    });
  }

  encryptData(data) {
    try {
      return CryptoJS.AES.encrypt(
        JSON.stringify(data),
        this.SECRET_KEY
      ).toString();
    } catch (e) {
      return;
    }
  }

  decryptData(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, this.SECRET_KEY);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      return;
    }
  }

  // This function called from page Core component load and resize
  checkWindowWidth(windowWidth: number) {
    if (this.navLayout === 'vertical') {
      if (windowWidth >= 768 && windowWidth <= 1024) {
        this.defaultNavbar = this.defaultVerticalMenu[1];
        this.toggleNavbar = this.onToggleVerticalMenu[1];
        this.navBarEffect = this.navBarMenuEffect[1];
        this.deviceType = this.defaultDeviceType[1];
      } else if (windowWidth < 768) {
        this.defaultNavbar = this.defaultVerticalMenu[0];
        this.toggleNavbar = this.onToggleVerticalMenu[0];
        this.navBarEffect = this.navBarMenuEffect[0];
        this.deviceType = this.defaultDeviceType[0];
      } else {
        this.defaultNavbar = this.defaultVerticalMenu[2];
        this.toggleNavbar = this.onToggleVerticalMenu[2];
        this.navBarEffect = this.navBarMenuEffect[2];
        this.deviceType = this.defaultDeviceType[2];
      }
    } else if (this.navLayout === 'horizontal') {
      if (windowWidth >= 768 && windowWidth <= 1024) {
        this.deviceType = this.defaultDeviceType[1];
      } else if (windowWidth < 768) {
        this.deviceType = this.defaultDeviceType[0];
      } else {
        this.deviceType = this.defaultDeviceType[2];
      }
    }
    this.getDefaultNavbar(this.defaultNavbar);
    this.getToggleNavbar(this.toggleNavbar);
    this.getNavBarEffect(this.navBarEffect);
    this.getDeviceType(this.deviceType);
  }

  getVerticalNavbarOnWindowResize(windowWidth: number) {
    this.checkWindowWidth(windowWidth);
  }
}
