import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationInterceptor } from '../authentication-interceptor/authentication-interceptor';
import { AuthenticationComponent } from '../authentication.component';
import { AuthenticationService } from '../authentication-service/authentication.service';
import { AuthenticationRoutes } from '../authentication-route/authentication.route';
import { NgZorroAntdModule } from 'src/app/core/module/ng-zorro-antd.module';
import { NgxSpinnerModule } from 'ngx-bootstrap-spinner';
import { NgxSpinnerModule as spinner } from "ngx-spinner";
import { WebcamModule } from 'ngx-webcam';
import { SharedModule } from 'src/app/core/module/shared-module';
import { TranslateModule } from '@ngx-translate/core';
import { AuthenticationGuard } from '../authentication-guard/authentication.guard';

@NgModule({
  declarations: [AuthenticationComponent],
  imports: [
    SharedModule,
    CommonModule,
    spinner,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    HttpClientModule,
    WebcamModule,
    AuthenticationRoutes,

    TranslateModule,
    NgZorroAntdModule,
  ],
  providers: [
    AuthenticationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AuthenticationComponent],
})
export class AuthenticationModule {}
