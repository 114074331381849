// import 'rxjs/add/operator/do';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private _router: Router, private message:NzMessageService) {
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {

        if(err.status == 401 && err.statusText == "Unauthorized" && err.error.error_code != 'H008' && err.error.error_code != 'HI007') {
        
          localStorage.clear();
          this._router.navigate(['login']);
   
        }
        else if(err && err.error && err.error.detail && err.error.detail.includes('expired')){
          this.message.error('Session expired!', {
            nzDuration: 7000,
          });
          localStorage.clear();
          this._router.navigate(['login']);
        }
       
      }
    }));
  }
}