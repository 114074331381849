import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LayoutService } from 'src/app/core/services/layout.service';
import { NgxSpinnerService as SpinUIloaderService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/features/authentication/authentication-service/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  leftPanelStatus = false;
  @Input() navLayout: string;
  @Input() defaultNavbar: string;
  @Input() toggleNavbar: string;
  @Input() toggleStatus: boolean;
  @Input() navbarEffect: string;
  @Input() deviceType: string;
  @Input() headerColorTheme: string;
  @Input() leftHeaderColorTheme: string;
  @Input() navbarColorTheme: string;
  @Input() activeNavColorTheme: string;
  @Input() headerHeight: number;
  @Input() collapsedLeftHeader: boolean;

  languages = undefined;
  subscriptions: Subscription[] = [];
  showingLangs = [];
  availableDashboards = ['Management Dashboard', 'Configuration Dashboard'];
  availableDashboardURLs = {
    'Management Dashboard': 'pages/dashboard',
    'Configuration Dashboard': 'config/dashboard',
  };
  selectedLang = '';
  selectedDashboard = '';
  userName = '';

  constructor(
    private layoutService: LayoutService,
    public _router: Router,
    private spinnerUIloader: SpinUIloaderService,
    public translate: TranslateService,
    public authService: AuthenticationService,
  ) {

    if (!this.authService.currentUserRole) {
      this.authService.currentUserRole = localStorage.getItem('userRole');
    }
  
    this.layoutService.changeInSessionStorageUserName.subscribe((res) => {
      this.userName =
        (localStorage.getItem('firstName')
          ? localStorage.getItem('firstName')
          : '') +
        ' ' +
        (localStorage.getItem('lastName')
          ? localStorage.getItem('lastName')
          : '');
    });

    this.translate.addLangs(['en', 'ur']);
    this.showingLangs = translate.getLangs();
    this.showingLangs = this.showingLangs.filter((el) => {
      return el != null || el != undefined || el != '';
    });
    this.selectedLang = localStorage.getItem('selectedLanguage');
    this.selectedDashboard = this._router.url.includes('config')
      ? this.availableDashboards[1]
      : this.availableDashboards[0];

    this.layoutService.selectedLanguage.next(
      localStorage.getItem('selectedLanguage')
    );
    this.translate.use(localStorage.getItem('selectedLanguage'));

    this.subscriptions.push(
      this.layoutService.leftPanelToggle.subscribe((res) => {
        this.leftPanelStatus = res;
      })
    );

    this.spinnerUIloader.show('langLoading');
    this.translate.get('Lang Loading..').subscribe((text: string) => {
      if (text) {
        this.spinnerUIloader.hide('langLoading');
      }
    });
  }

  changeLang() {
    localStorage.setItem('selectedLanguage', this.selectedLang);
    this.layoutService.selectedLanguage.next(
      localStorage.getItem('selectedLanguage')
    );
    this.translate.use(localStorage.getItem('selectedLanguage'));

    this.spinnerUIloader.show('langLoading');
    this.translate.get('Lang Loading..').subscribe((text: string) => {
      this.spinnerUIloader.hide('langLoading');
    });
  }

  /**
   * On Change Dashboard
   * @param name
   */
  changeDashboard(name: any) {
    this.selectedDashboard = name;
    this._router.navigate([this.availableDashboardURLs[name]]);
  }

  ngOnInit() {}

  changeTheToggleStatus() {
    this.layoutService.getToggleStatus();
  }

  changeTheLeftPanelStatus() {
    this.leftPanelStatus = !this.leftPanelStatus;
    this.layoutService.leftPanelToggle.next(this.leftPanelStatus);
  }

  LogOut() {
    const user = localStorage.getItem('currentUser_LOMAH');
    if (user) {
      localStorage.removeItem('currentUser_LOMAH');
      localStorage.removeItem('username');
      localStorage.removeItem('lastName');
      localStorage.removeItem('firstName');
      localStorage.removeItem('userType');
      localStorage.removeItem('userRole');
      localStorage.removeItem('perms');
      this._router.navigate(['login']);
      this.authService.currentUserRole = '';
    }
  }

  gotoRoute(link: string) {
    this._router.navigate([link]);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
