import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { NoPageFoundComponent } from './core/components/error-pages/no-page-found/no-page-found.component';
import { NoRolesComponent } from './core/components/error-pages/no-roles/no-roles.component';

import { AuthenticationGuard } from './features/authentication/authentication-guard/authentication.guard';
import { LoginRedirectGuard } from './features/authentication/authentication-guard/login-redirect.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    canActivate: [LoginRedirectGuard],
    component: AppComponent,
  },
  // {
  //   path: 'login',
  //   canActivate: [LoginRedirectGuard],
  //   loadChildren: () =>
  //     import(
  //       './features/authentication/authentication-module/authentication.module'
  //     ).then((m) => m.AuthenticationModule),
  // },
  {
    path: 'pages',
    canActivate: [AuthenticationGuard],
    loadChildren: () =>
      import('./features/dashboard/pages-modules/pages.module').then(
        (m) => m.PagesModule
      ),
  },
  {
    path: 'config',
    canActivate: [AuthenticationGuard],
    loadChildren: () =>
      import('./features/config/config.module').then((m) => m.ConfigModule),
  },
  { path: 'access-forbidden', component: NoRolesComponent },

  { path: '**', redirectTo: 'pageNotFound' },

  { path: 'pageNotFound', component: NoPageFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes), TranslateModule],
  exports: [RouterModule, TranslateModule],
})
export class AppRoutingModule {}
