import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgZorroAntdModule } from './core/module/ng-zorro-antd.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AuthenticationModule } from './features/authentication/authentication-module/authentication.module';
import { AuthenticationRoutes } from './features/authentication/authentication-route/authentication.route';
import { SharedModule } from './core/module/shared-module';
import { NoPageFoundComponent } from './core/components/error-pages/no-page-found/no-page-found.component';
import { NoRolesComponent } from './core/components/error-pages/no-roles/no-roles.component';
import { NgxSpinnerModule } from 'ngx-bootstrap-spinner';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AppComponent, NoPageFoundComponent, NoRolesComponent],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    AuthenticationModule,
    AuthenticationRoutes,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxSpinnerModule,
    NgZorroAntdModule,
    NgbModule,
    SharedModule,
    HttpClientModule,
    TranslateModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
