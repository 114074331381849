import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthenticationService {
  private authURL = environment.serverIP;
  private getRolesPerms = 'api/list_role_permissions/';
  copyrightYear: BehaviorSubject<string> = new BehaviorSubject(
    new Date().getFullYear().toString()
  );
    
  private faceLoginURL = 'face_auth/authenticate_face/';
  ErrorMessage: BehaviorSubject<string> = new BehaviorSubject('');

  httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Content-Type': 'application/json',
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
    })
  };

  public currentUserRole = '';
  
  constructor(private http: HttpClient) {}

  setHeader() {
    let options = undefined;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      timestamp: new Date().toISOString(),
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
    });
      options = {
        headers: headers,
      };

    return options;
  }

  setTokenHeader(token) {
    let options = undefined;
    if (token) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'jwt ' + token,
        timestamp: new Date().toISOString(),
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
      });
      options = {
        headers: headers,
      };
    }
    return options;
  }

  getPermissionsAgainstUser(username,token): Observable<any>{
    return new Observable((observer) => {
      this.http
        .post(this.authURL + this.getRolesPerms, {username:username}, this.setTokenHeader(token))
        .subscribe(
          (res) => {
            let response: any = res;
            console.log(response);
            
            if (
              response.error == '' &&
              response.data &&
              Object.keys(response.data).length
            ) {
              observer.next({
                status: 'ok',
                permissions: response.data.role_permissions,
                user:response.data.user_details
              });
              observer.complete();
            } else {
              observer.next({ status: 'error', msg: response.error });
              observer.complete();
            }
          },
          (err) => {
            this.ErrorMessage.next(
              'No permissions found against user ' + username + '. Refresh page and try again!'
            );
            observer.next({ status: 'error',err:err });
            observer.complete();
          }
        );
    });
  }

  loginUser(credentials): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(
          this.authURL + 'api-token-auth/',
          { username: credentials.userName, password: credentials.password },
          this.httpOptions
        )
        .subscribe(
          (data) => {
            let response: any = data;
            if (response && response.token) {
              observer.next({ status: 'ok', token: response.token });
              observer.complete();
            } else {
              observer.next({ status: 'error' });
              observer.complete();
            }
          },
          (err) => {
            if(err.error && err.error.non_field_errors && err.error.non_field_errors && err.error.non_field_errors.length && err.error.non_field_errors[0].includes('Unable to log in')){
              let msg = 'Unable to login with the provided credentials';
              this.ErrorMessage.next(
                'Unable to login with the provided credentials!'
              );
              observer.next({ status: 'error',errorMsg: msg });
              observer.complete();
            }
            else if(err.error.username[0].includes('This field may not be null') || err.error.password[0].includes('This field may not be null')){
              let msg = 'Field can not be null';
              observer.next({ status: 'error', errorMsg: msg });
              observer.complete();
            }
            else{
              this.ErrorMessage.next(
                'Error in logging in, please try again!'
              );
              observer.next({ status: 'error' });
              observer.complete();
            }
     
          }
        );
    });
  }

  faceLoginAuth(path,userName): Observable<any>{
    return new Observable((observer) => {
      this.http
      .post(
        this.authURL + this.faceLoginURL,
        { username: userName, picture: path },
        this.setHeader()
      )
      .subscribe(
        (data) => {
          let response: any = data;
          
          if (response && response.token) {
            observer.next({ status: 'ok', token: response.token });
            observer.complete();
          } else {
            observer.next({ status: 'error' , msg:'Face not recognized, Try again!' });
            observer.complete();
          }
        },
        (err) => {
          this.ErrorMessage.next(
            'Service Temporarily unavailable, please try again'
          );
          observer.next({ status: 'error', err: err });
          observer.complete();
        }
      );
    });
  }
  

  public getCopyrightYear(): Observable<string> {
    return this.copyrightYear.asObservable();
  }

}
