import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { NgxSpinnerService as SpinUIloaderService } from 'ngx-spinner';
import { WebcamImage } from 'ngx-webcam';
import { Observable, Subject, Subscription } from 'rxjs';
import { LayoutService } from 'src/app/core/services/layout.service';
import { AuthenticationService } from './authentication-service/authentication.service';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent implements OnInit {
  showPassword: boolean = false;
  copyRightYear: string = '';
  errorMessage: string = '';
  returnUrl: string = '';
  subscriptions: Subscription[] = [];
  private trigger: Subject<void> = new Subject<void>();
  updateUser = {
    is_active: true,
  };

  public webcamImage: WebcamImage = null;
  isFaceSearch = false;
  loginForm: FormGroup;
  languages = undefined;
  showingLangs = [];
  selectedLang = 'en';

  setNavLayout: string;
  themeLayout: string;
  setDefaultNavbar: string;
  setToggleNavbar: string;
  setToggleStatus: boolean;
  setVerticalNavbarEffect: string;
  setDeviceType: string;
  setHeaderColorTheme: string;
  setLeftHeaderColorTheme: string;
  setNavbarColorTheme: string;
  setActiveNavColorTheme: string;
  setHeaderHeight: number;
  setFooterHeight: number;
  setCollapsedLeftHeader: boolean;

  constructor(
    private authService: AuthenticationService,
    private _router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private spinnerUIloader: SpinUIloaderService,
    private formBuilder: FormBuilder,
    private layoutService: LayoutService,
    private message: NzMessageService,
    private translate: TranslateService
  ) {
    this.subscriptions.push(
      this.authService.getCopyrightYear().subscribe((copyRightYear) => {
        this.copyRightYear = copyRightYear;
      })
    );

    this.translate.addLangs(['en', 'ur']);
    this.showingLangs = translate.getLangs();

    this.layoutService.selectedLanguage.next(
      localStorage.getItem('selectedLanguage')
    );
    this.translate.use(localStorage.getItem('selectedLanguage'));

    this.spinnerUIloader.show('langLoading');
    this.translate.get('Lang Loading..').subscribe((text: string) => {
      if (text) {
        this.spinnerUIloader.hide('langLoading');
      }
    });

    this.subscriptions.push(
      this.authService.ErrorMessage.subscribe((err) => {
        if (err) {
          this.errorMessage = err;
          this.message.error(this.errorMessage, {
            nzDuration: 5000,
          });
        }
      })
    );

    if (!localStorage.getItem('themeStatus')) {
      localStorage.setItem(
        'themeStatus',
        JSON.stringify(this.updateUser['is_active'])
      );
    }

    this.layoutService.checkWindowWidth(window.innerWidth);

    this.layoutService.navLayoutCast.subscribe(
      (navlayout) => (this.setNavLayout = navlayout)
    );

    this.layoutService.dfNavbarCast.subscribe(
      (dfNavbar) => (this.setDefaultNavbar = dfNavbar)
    );
    this.layoutService.toggleNavbarCast.subscribe(
      (tNavbar) => (this.setToggleNavbar = tNavbar)
    );
    this.layoutService.tStatusCast.subscribe(
      (tStatus) => (this.setToggleStatus = tStatus)
    );
    this.layoutService.nvEffectCast.subscribe(
      (nvEffect) => (this.setVerticalNavbarEffect = nvEffect)
    );
    this.layoutService.headerThemeCast.subscribe(
      (headerTheme) => (this.setHeaderColorTheme = headerTheme)
    );
    this.layoutService.leftHeaderThemeCast.subscribe(
      (leftHeaderTheme) => (this.setLeftHeaderColorTheme = leftHeaderTheme)
    );
    this.layoutService.navbarThemeCast.subscribe(
      (navbarTheme) => (this.setNavbarColorTheme = navbarTheme)
    );
    this.layoutService.activeNavThemeCast.subscribe(
      (activeNavTheme) => (this.setActiveNavColorTheme = activeNavTheme)
    );
    this.layoutService.themeLayoutCast.subscribe(
      (themeLayout) => (this.themeLayout = themeLayout)
    );
    this.layoutService.collapsedLeftHeaderCast.subscribe(
      (collapsedLeftHeader) =>
        (this.setCollapsedLeftHeader = collapsedLeftHeader)
    );
    this.layoutService.deviceTypeCast.subscribe(
      (appDeviceType) => (this.setDeviceType = appDeviceType)
    );

    this.setHeaderHeight = this.layoutService.headerHeight;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.layoutService.getVerticalNavbarOnWindowResize(event.target.innerWidth);
  }
  changeTheToggleStatus() {
    this.layoutService.getToggleStatus();
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this._router.navigate([this.returnUrl]);

    this.loginForm = this.formBuilder.group({
      userName: [localStorage.getItem('username'), [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  Login() {
    if (this.loginForm.valid) {
      this.spinner.show('loginLoading');
      this.authService.loginUser(this.loginForm.value).subscribe(
        (result) => {
          if (result.status == 'ok') {
            if (result.token) {
              this.authService
                .getPermissionsAgainstUser(
                  localStorage.getItem('username'),
                  result.token
                )
                .subscribe((response) => {
                  if (response.status == 'ok') {
                    this.layoutService.changeInSessionStorageUserName.next(
                      true
                    );
                    localStorage.setItem('userType', response.user.user_type);
                    localStorage.setItem('userRole', response.user.role);
                    this.authService.currentUserRole = response.user.role;
                    if (response.user.first_name)
                    localStorage.setItem(
                        'firstName',
                        response.user.first_name
                      );
                    if (response.user.last_name)
                    localStorage.setItem(
                        'lastName',
                        response.user.last_name
                      );

                    if (response.permissions && response.permissions.length) {
                      let encryptedData = this.layoutService.encryptData(
                        response.permissions
                      );

                      localStorage.setItem('perms', encryptedData);

                      localStorage.setItem('currentUser_LOMAH', result.token);
                      this._router.navigate(['pages/dashboard']);
                      this.spinner.hide('loginLoading');
                    } else {
                      this.message.error(
                        'No role assigned to user ' +
                        localStorage.getItem('pkNumber'),
                        {
                          nzDuration: 5000,
                        }
                      );

                      this.spinner.hide('loginLoading');
                      this._router.navigate(['access-forbidden']);
                      return;
                    }
                  } else {
                    if (this.errorMessage == '') {
                      this.message.error(
                        'No permissions found against user ' +
                        localStorage.getItem('pkNumber') +
                          '. Refresh page   try again!',
                        {
                          nzDuration: 5000,
                        }
                      );
                    }
                    this.spinner.hide('loginLoading');
                    this._router.navigate(['login']);

                    return;
                  }
                });
            }
          } else {
            this.spinner.hide('loginLoading');
            this._router.navigate(['login']);
            return;
          }
        },
        (error) => {
          this.spinner.hide('loginLoading');
          this._router.navigate(['login']);
          return;
        }
      );
    }
  }

  OpenCamera() {
    this.isFaceSearch = true;
    if(navigator && navigator.mediaDevices) {
      navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then((stream) => {
        console.log('camera/webcam opening success: ', stream);
      })
      .catch((err) => {
        console.log('Error in opening camera/webcam: ', err);
      });
    }
    else {
      // invokes camera/webcam if permissions are not given
      this._invokeCameraWebcam();
    }
  }

  handleImage(webcamImage: WebcamImage) {
    this.webcamImage = webcamImage;
  }

  EditUsername() {
    this.layoutService.editUserName.next(localStorage.getItem('username'));
    this._router.navigate(['login']);
  }

  UploadImage() {
    if (this.webcamImage && this.webcamImage.imageAsBase64) {
      this.spinner.show('uploadCameraLoading');
      this.authService
        .faceLoginAuth(
          this.webcamImage.imageAsBase64,
          localStorage.getItem('username')
        )
        .subscribe((res) => {
          if (res.status == 'ok') {
            if (res.token) {
              this.authService
                .getPermissionsAgainstUser(
                  localStorage.getItem('username'),
                  res.token
                )
                .subscribe((response) => {
                  if (response.status == 'ok') {
                    this.layoutService.changeInSessionStorageUserName.next(
                      true
                    );
                    localStorage.setItem('userType', response.user.user_type);
                    if (response.user.first_name)
                    localStorage.setItem(
                        'firstName',
                        response.user.first_name
                      );
                    if (response.user.last_name)
                    localStorage.setItem(
                        'lastName',
                        response.user.last_name
                      );

                    if (response.permissions && response.permissions.length) {
                      let encryptedData = this.layoutService.encryptData(
                        response.permissions
                      );

                      localStorage.setItem('perms', encryptedData);
                      localStorage.setItem('currentUser_LOMAH', res.token);
                      this._router.navigate(['pages/dashboard']);

                      this.isFaceSearch = false;
                      this.webcamImage = undefined;

                      this.spinner.hide('uploadCameraLoading');
                    } else {
                      this.message.error(
                        'No role assigned to user ' +
                        localStorage.getItem('pkNumber'),
                        {
                          nzDuration: 5000,
                        }
                      );

                      this.spinner.hide('uploadCameraLoading');
                      this._router.navigate(['access-forbidden']);
                      return;
                    }
                  } else {
                    if (this.errorMessage == '') {
                      this.message.error(
                        'No permissions found against user ' +
                        localStorage.getItem('pkNumber') +
                          '. Refresh page   try again!',
                        {
                          nzDuration: 5000,
                        }
                      );
                    }
                    this.spinner.hide('uploadCameraLoading');
                    this._router.navigate(['login']);

                    return;
                  }
                });
            }
          } else {
            this.spinner.hide('uploadCameraLoading');

            this.isFaceSearch = false;
            this.webcamImage = undefined;
            if (res && res.msg) {
              this.message.error(res.msg, {
                nzDuration: 7000,
              });
            }
          }
        });
    }
  }
  ResetImage() {
    this.webcamImage = undefined;
    this.isFaceSearch = false;
    setTimeout(() => {
      this.webcamImage = undefined;
      this.isFaceSearch = false;
    }, 0);
  }

  takeSnapShot() {
    this.trigger.next();
    this.isFaceSearch = false;
  }

  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  /**
   * Invoke Camera/Webcam access
   */
  _invokeCameraWebcam() {
    const customNavigator = {} as any;

    // Older browsers might not implement mediaDevices at all, so we set an empty object first
    customNavigator.mediaDevices = {} as any;

    if (customNavigator.mediaDevices.getUserMedia === undefined) {
      customNavigator.mediaDevices.getUserMedia = function (constraints) {
        // First get ahold of the legacy getUserMedia, if present
        var getUserMedia =
          navigator['webkitGetUserMedia'] || navigator['mozGetUserMedia'];

        // Some browsers just don't implement it - return a rejected promise with an error
        // to keep a consistent interface
        if (!getUserMedia) {
          return Promise.reject(
            new Error('getUserMedia is not implemented in this browser')
          );
        }

        // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
        return new Promise(function (resolve, reject) {
          getUserMedia.call(customNavigator, constraints, resolve, reject);
        });
      };
    }

    // invokes camera/webcam if permissions are not given
    customNavigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        console.log('camera/webcam opening success: ', stream);
      })
      .catch((err) => {
        console.log('Error in opening camera/webcam: ', err);
      });
  }

  ngOnDestroy() {
    this.webcamImage = undefined;
    this.isFaceSearch = false;
    this.authService.ErrorMessage.next('');
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
