<ng-container *ngIf="!isNumber; then notauthenticated; else authenticate">
</ng-container>

<ng-template #notauthenticated>
    <div class="d-flex flex-column align-items-center justify-content-center min-h-100 card-wrapper" *ngIf="!isNumber">
        <div class="brand">
            <img src="assets/logo.png" alt="Range Management System"/>
        </div>
        <div class="card">
            <form nz-form class="ant-advanced-search-form">
                <div nz-row [nzGutter]="24">
                    <div nz-col [nzSpan]="24">
                        <nz-form-item class="d-flex flex-column">
                            <div class="d-flex align-items-center justify-content-between">
                                <label class="d-block w-100 text-left">Username / PAK Army ID *</label>
                                <nz-form-label class="d-block w-100 text-right column-remove">* صارف کا نام / پاک فوج کی آئی ڈی </nz-form-label>
                            </div>
                            <nz-form-control class="flex-inherit mb-3">
                                <input nz-input placeholder="Enter Army ID / Username"  name="username" [(ngModel)]="username"/>
                            </nz-form-control>

                            <div class="d-flex align-items-center justify-content-between">
                                <label class="d-block w-100 text-left">Password *</label>
                                <nz-form-label class="d-block w-100 text-right column-remove">* صارف کا پاس ورڈ </nz-form-label>
                            </div>
                                <nz-input-group [nzSuffix]="suffixTemplate">
                                    <nz-form-control class="flex-inherit mb-3">
                                        <input
                                          [type]="showPassword ? 'text' : 'password'"
                                          [(ngModel)]="password"
                                          name="password"
                                          nz-input
                                          placeholder="Enter password"
                                        />
                                    </nz-form-control>
                                </nz-input-group>
                                <ng-template #suffixTemplate>
                                  <span
                                    style="cursor: pointer;"
                                    nz-icon
                                    [nzType]="showPassword ? 'eye' : 'eye-invisible'"
                                    (click)="showPassword = !showPassword"
                                  ></span>
                                </ng-template>

                            <div class="d-flex align-items-center justify-content-between">
                                <label class="d-block w-100 text-left">Language*</label>
                                <nz-form-label class="d-block w-100 text-right column-remove">*زبان</nz-form-label>
                            </div>
                            <nz-select name="language" nzPlaceHolder="Select Language" [nzNotFoundContent]="'Languages not found'" [(ngModel)]="selectedLang" *ngIf="showingLangs && showingLangs.length">
                                <nz-option *ngFor = "let lang of showingLangs" [nzValue]="lang" [nzLabel]="lang"></nz-option>
                            </nz-select>
                        </nz-form-item>
                    </div>
                </div>
                
                <div class="d-flex justify-content-end">
                    <button nz-button class="btn-secondary" [disabled]="!selectedLang || !username || !password" (click)="SubmitPakNumber()">
                        <ngx-bootstrap-spinner
                        name="checking"
                        bdColor="rgba(0, 0, 0, 0.8)"
                        size="small"
                        color="#d91d1d"
                        type="border"
                        isButtonSpinner="true"
                        autoDisableButton="true"
                        loadingText=""
                        style="margin-right: 5px;"
                      >
                      </ngx-bootstrap-spinner> 
                        Login</button>
                </div>
              </form>
        </div>
    </div>
</ng-template>

<ng-template #authenticate>
  <router-outlet></router-outlet>
</ng-template>
