import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe } from '../pipes/filter.pipe';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HeaderComponent } from 'src/app/common/header/header.component';
import { NgZorroAntdModule } from './ng-zorro-antd.module';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule as spinner } from "ngx-spinner";

export class LanguageLoader implements TranslateLoader {
  authURL = environment.serverIP;
  endPoint = 'lt/get_localization/';
  constructor(public http: HttpClient) {}

  setHeader() {
    let options = undefined;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      timestamp: new Date().toISOString(),
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
    });
    options = {
      headers: headers,
    };
    return options;
  }

  public getTranslation(lang: String): Observable<any> {
    return new Observable((observer) => {
      let obj = undefined;
      this.http
        .get(this.authURL + this.endPoint, this.setHeader())
        .subscribe((response) => {
          let res: any = response;
          if (res.error == '' && res.data && Object.keys(res.data).length) {
            switch (lang) {
              case 'en':
                obj = res.data.en;
                break;
              case 'ur':
                obj = res.data.ur;
                break;

              default:
                obj = res.data.en;
            }
            observer.next(obj);
            observer.complete();
          } else {
            return undefined;
          }
        });
    });
  }
}

@NgModule({
  declarations: [FilterPipe,HeaderComponent],
  exports: [FilterPipe,HeaderComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    spinner,
    NgZorroAntdModule,
    TranslateModule.forRoot({
      loader: {
        useClass: LanguageLoader,
        provide: TranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
})
export class SharedModule {
}
