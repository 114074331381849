import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AuthenticationComponent } from '../authentication.component';
import { LoginRedirectGuard } from '../authentication-guard/login-redirect.guard';

const routes: Routes = [
  // { path: '', redirectTo:'login',pathMatch:'full' },
  // { path: 'login', canActivate: [LoginRedirectGuard], component: AuthenticationComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes),TranslateModule],
  exports: [RouterModule],
})
export class AuthenticationRoutes {}
